<template>
  <div class="container">
      <div class="img-box1">
          <div class="img-text">征集合伙人</div>
      </div>
      <div class="img-box2">
          <div class="left">
              <div>我们实现<p></p></div>
              <div style="margin-bottom:4%">征集合伙人<p></p></div>
          </div>
          <ul class="right">
              <li>
                  <i class="i1"></i>
                  <i class="i2"></i>
                  <i class="i3"></i> 
                  <p>塔吊作业安全高效</p>
              </li>
               <li>
                  <i class="i1"></i>
                  <i class="i2"></i>
                  <i class="i3"></i> 
                  <p>塔吊管理降本增效</p>
              </li>
               <li>
                  <i class="i1"></i>
                  <i class="i2"></i>
                  <i class="i3"></i> 
                  <p>多功能云平台+创新型硬件解决方案+强力的渠道支持</p>
              </li>
               <li>
                  <i class="i1"></i>
                  <i class="i2"></i>
                  <i class="i3"></i> 
                  <p>欢迎有渠道资源政府资源客户资源的你与我们合作共赢</p>
              </li>
          </ul>
      </div>
      <div class="title" style="margin-top:20px">
          <h3>合作交流</h3>
          <p>Cooperation And Exchange</p>
       </div>
       <div class="item" v-for="(item,i) in list" :key="i">
           <div class="father">
               <div class="top">
                   <div class="item-title">{{item.companyName}}</div>
                   <div class="time">{{item.createTime}}</div>
               </div>
               <div class="bottom">
                   {{item.content}}
               </div>
           </div>
           <div class="children" v-for="(item1,i1) in item.children" :key="i1">
               <div class="top">
                   <div class="item-title">{{item1.createUser}}  <span style="color:#4D97FF">回复 </span>{{item.companyName}}</div>
                   <div class="time">{{item1.createTime}}</div>
               </div>
               <div class="bottom">
                   {{item1.content}}
               </div>
               <div class="sanjiao"></div>
           </div>
       </div>
       <div class="page">
           <div @click="prev">上一页</div>
           <input v-model="pageCurrent" type="number" @keyup.enter="pageChange"/>/ {{pageToatal}}
           <div @click="next">下一页</div>
       </div>
       <div class="tiwen-title">
           <div>我要提问</div>
           <!-- <p>Put questions</p> -->
       </div>
       <div class="input-box">
           <p>公司名称</p>
           <input v-model="companyName" type="text">
           <p style="margin-left:15%">联系方式</p>
           <input v-model="phone" type="text">
       </div>
       <div class="area-box">
           <p>问题描述</p>
           <textarea rows="3" v-model="content"/>
       </div>
       <div class="btn-box">
           <button @click="submit">提交留言</button>
       </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            options:{
                pageSize: 5,
                pageIndex: 1,
            },
            companyName:'',
            phone:'',
            content:'',
            list:[],
            total:0,
            pageToatal:1,
            pageCurrent:1,
        }
    },
    methods:{
        //获取问题树图
        getQuestionsTree(options){
            this.$api.getQuestionsTree(options).then(res=>{
                this.list = res.data.data;
                this.bubbleSort(this.list);
                this.total = res.data.total;
                this.pageToatal = Math.ceil(this.total / this.options.pageSize)
            })
        },
        //排序
        bubbleSort(array) {
            let i = array.length - 1
            while (i > 0) {
                let pos = 0
                for (let j = 0; j < i; j++) {
                    if (array[j].createTime.replace(/-|:|\ /g,'') < array[j + 1].createTime.replace(/-|:|\ /g,'')) {
                        pos = j
                        const temp = array[j]
                        array[j] = array[j + 1]
                        array[j + 1] = temp
                    }
                }
                i = pos
            }
            return array
        },
        //上一页
        prev(){
            if (this.options.pageIndex <= 1) return;
            this.options.pageIndex --;
            this.pageCurrent = this.options.pageIndex
            this.getQuestionsTree(this.options);
        },
        //下一页
        next(){
            if(this.pageCurrent >= this.pageToatal) return
            this.options.pageIndex ++;
            this.pageCurrent = this.options.pageIndex
            this.getQuestionsTree(this.options);
        },
        //页码改变
        pageChange(){
            if(this.pageCurrent == '' || this.pageCurrent <= 0){
                this.pageCurrent = 1
            }
            if(this.pageCurrent > this.pageToatal){
                this.pageCurrent = this.pageToatal
            }
            this.options.pageIndex = this.pageCurrent
            this.getQuestionsTree(this.options);
        },
        submit(){
            if(this.companyName=='' || this.content == ''){
                this.$message({
                    message: '请输入公司名称和我的问题！',
                    type: 'error'
                })
                return;
            }
            this.$api.addQuestions({
                companyName:this.companyName,
                phone:this.phone,
                content:this.content,
            }).then(res=>{
                if(res.data.code == 200){
                    this.getQuestionsTree(this.options);
                    this.$message({
                        message: res.data.message,
                        type: 'success'
                    })
                }else{
                    this.$message({
                        message: res.data.message,
                        type: 'error'
                    })
                }
                return;
            })
        }
    },
    mounted(){
        this.getQuestionsTree(this.options);
    }
}
</script>

<style lang="scss" scoped>
.container{
    width: 1200px;
    margin: 0 auto;
    height: auto;
}
.title{
    color: #4D4D4D;
    letter-spacing: 3px;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3{
        font-size: 21px;
    }
    p{
        color: #808080;
        font-size: 15px;
        letter-spacing: 0;
    }
}
.img-box1{
    width: 820px;
    height: 70px;
    background: url(../assets/images/合伙人1.webp) no-repeat center center;
    background-size: 100% 100%;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .img-text{
        border-bottom: 2px solid #4D97FF;
        font-weight: bold;
        font-size: 26px;
        color: rgba($color: #4D4D4D, $alpha: 0.8);
        letter-spacing: 4px;
        padding-bottom: 2px;
        text-align: center;
    }
}
.img-box2{
    height: 350px;
    background: url(../assets/images/合伙人2.webp) no-repeat center center;
    padding: 30px 0;
    display: flex;
    .left{
        //border: 1px solid red;
        width: 200px;
        height: 100%;
        margin-left: 190px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div{
            background: #4D97FF;
            width: 160px;
            height: 50px;
            color: #fff;
            font-weight: bold;
            font-size: 18px;
            letter-spacing: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            box-shadow: -0px -0px 20px #fff;
            p{
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-left: 20px solid #4D97FF;
                border-bottom: 10px solid transparent;
                position: absolute;
                right: -20px;
            }
        }
    }
    .right{
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 2%;
        li{
            position: relative;
            flex: 1;
            display: flex;
            align-items: center;
            letter-spacing: 1px;
            i {
                position: absolute;
                top: 40%;
                left: 0;
                display: block;
                width: 0;
                height: 0;
                border-right: 15px solid transparent;
                border-bottom: 10px solid #4D97FF;
                border-left: 15px solid transparent;
                color: #4D97FF;
            }
            .i1{
                transform: rotate(108deg);
            }
            .i2{
                transform: rotate(35deg);
            }
            .i3{
                transform: rotate(-35deg);
            }
            p{
                margin-left: 40px;
                color: #666666;
                font-weight: bold;
            }
        }
    }
}
.item{
    width: 1000px;
    margin: 15px auto;
    padding: 20px 80px;
    border-bottom: 1px dashed #CCCCCC;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .father, .children{
        padding: 10px 30px;
        width: 100%;
        background: #E7EAF1;
        min-height: 75px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 7px 5px 10px 0px #C5C5C5, -6px -6px 9px 1px #FFFFFF, 0px 3px 5px 0px #F4F7FF, 2px 2px 5px 0px #DBDEE4;
        .top{
            display: flex;
            .item-title{
                color: #4D4D4D;
                font-weight: bold;
                letter-spacing: 1px;
                font-size: 15px;
            }
            .time{
                color: #666;
                font-size: 12px;
                margin-left: 1.5%;
                padding-top: 0.8%;
            }
        }
        .bottom{
            font-size: 14px;
            margin-top: 10px;
        }
    }
    .children{
        margin-top: 4%;
        width: 95%;
        position: relative;
        letter-spacing: 1px;
        .sanjiao{
            position: absolute;
            width: 0;
            height: 0;
            top: -20px;
            left: 60px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 20px solid #E7EAF1;
        }
    }
}
.page{
    height: 50px;
    color: #4D4D4D;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    input{
        width: 40px;
        height: 22px;
        margin-right: 10px;
        padding-left: 0.5%;
        border-radius: 4px;
        border: 1px solid rgba($color: #4D7BFF, $alpha: 0.5);
        background: none;
    }
    div{
         margin: 0 10px;
        cursor: pointer;
    }
    div:hover{
        color: #4D7BFF;
        font-weight: bold;
    }
}
.tiwen-title{
    width: 800px;
    margin: 0 auto;
    padding: 20px 0;
    div{
        font-weight: bold;
        font-size: 17px;
        letter-spacing: 1px;
        color: #4D4D4D;
    }
    p{
        color: #808080;
        font-size: 13px;
    }
}
.input-box{
    width: 800px;
    margin: 0 auto;
    display: flex;
    padding: 10px 0;
    font-size: 15px;
    color: #4D4D4D;
    input{
        background: none;
        border: 1px solid #ccc;
        margin-left: 2%;
        width: 210px;
        padding: 3px;
        height: 30px;
        border-radius: 4px;
    }
}
.area-box{
    width: 800px;
    margin: 0 auto;
    display: flex;
    padding: 15px 0;
    font-size: 15px;
    color: #4D4D4D;
    textarea{
        margin-left: 2%;
        border: 1px solid #ccc;
        outline: none;
        height: 150px;
        width: 700px;
        background: none;
        padding: 3px;
        border-radius: 4px;
    }
}
.btn-box{
    width: 800px;
    margin: 0 auto;
    text-align: center;
    padding: 20px 0;
    button{
        background: #4D97FF;
        color: #fff;
        padding: 9px;
        cursor: pointer;
        border-radius: 4px;
    }
    button:active{
        background: rgba($color: #4D97FF, $alpha: 0.8);
    }
}
</style>